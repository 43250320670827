<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('routes')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('routes')" :isFilter="false"></HeaderMobile>
            </template>
            <div>
                <div class="mb-2">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>{{ $t('search') }}</b-input-group-text>
                        </template>
                        <b-form-input size="lg"
                                      class="rounded-sm"
                                      v-model="search"
                                      :placeholder="$t('search')"/>
                        <b-input-group-append>
                            <b-input-group-text v-if="search" @click="search=''">
                                <b-icon icon="x"/>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>

                </div>
                <div class="border rounded-sm p-4">
                    <table class="table table-bordered table-sm">
                        <tr v-for="(item,index) in computeItems">
                            <td class="text-center width-80">{{ index + 1 }}</td>
                            <td>
                                <router-link :to="item.path">
                                    {{ item.path }}
                                </router-link>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>

    // Template
    import AppLayout from '@/layouts/AppLayout.vue'
    import Header from '@/layouts/AppLayout/Header.vue'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile.vue'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile
        },
        metaInfo() {
            return {
                title: this.$t('routes')
            }
        },
        computed: {
            computeItems: function() {
                if(this.search){
                    return this.items.filter((item)=>{
                        return item.path.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                    });
                }
                else {
                    return this.items;
                }
            }
        },
        created() {
            this.$router.options.routes.forEach(route => {
                this.items.push({
                    name: route.name,
                    path: route.path
                })
            })
        },
        data() {
            return {
                items: [],
                search: null
            }
        }
    }
</script>
